import React from 'react';
import Layout from '../components/public/layout';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import default_photo from '../images/default_photo.png'
const Aktualnosci = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulOgloszeniaIIntencje{
            edges {
				node {
					title
					author
					publishDate
					content{
						raw
					}
					linkName
				}
            }
        }
        allContentfulArtykulyBiezace{
            edges {
				node {
					mainPhoto {
						file {
							url
						}
					}
					title
					author
					publishDate
					content{
						raw
					}
					linkName
				}
            }
        }
    }
    
    `)
    var full = data.allContentfulOgloszeniaIIntencje.edges.concat(data.allContentfulArtykulyBiezace.edges)
    full.sort((x, y) => {
        let a = new Date(x.node.publishDate)
        let b = new Date(y.node.publishDate);
        return b - a;
    })
    return (
        <Layout>
            <Helmet title={`Aktualności | Parafia w Starych Skoszewach`} />

            <div className="container">
                <div className="left-col" style={{width: '100%'}}>
                    <div className="list-of-articles">
                        <h2 style={{ lineHeight: '80px' }}>Aktualności</h2>
                        {
                            full.map((edge, i) => {
                                const jsonContent = JSON.parse(edge.node.content.raw)
                                return (
                                    <Link to={edge.node.linkName.startsWith('ogloszenia')
                                        ? `/ogloszenia-i-intencje/${edge.node.linkName}`
                                        : edge.node.linkName.startsWith('intencje')
                                            ? `/ogloszenia-i-intencje/${edge.node.linkName}`
                                            : `/artykuly/${edge.node.linkName}`}
                                        key={`news-single-${i}`}
                                    >
                                        <div className="single">
                                            <img src={edge.node.mainPhoto != null ? edge.node.mainPhoto.file.url : default_photo} alt="article" />
                                            <div className='main-content'>
                                                <span className='title'>{edge.node.title}</span><br />
                                                <span className='date' style={{ textTransform: 'initial' }}>{(new Date(edge.node.publishDate)).toLocaleDateString('pl', { year: 'numeric', month: 'long', day: 'numeric' })} | {edge.node.author}</span><br />
                                                <p>{jsonContent.content[0].content[0].value.substring(0, 200)} ...</p>
                                                <span style={{ textDecoration: 'underline', fontSize: 16, color: '#555' }}>CZYAJ DALEJ</span>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                {/* <div className="right-col">
                    <div className="facebook-panel"></div>
                </div> */}
            </div>
        </Layout>
    );
}

export default Aktualnosci;
